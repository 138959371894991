import http from "@/utils/request";


/**
 * 获取问卷详情
 * @param {
	 questionId
	 account=当前商家登录的账号（注意 是账号 非MDM编码）
 } data
 */
export function getQuestionnaire(data) {
	return http({
		method: "post",
		url: "/baseSurveyQuestion/getQuestionById.nd",
		data: data
	});
}

/**
 * 提交问卷
 * @param {
	//   "id": 1,  *    //问卷的id
	//      "questionSubDtoList": [  //问卷题目信息
	//          {
	//              "id": 1,  *
	//              "typeId": 701,  *
	//              "typeName": "单选题",  *
	//              "title": "谁是最好看的",  *
	//              "isRequire": "1",  *
	//              "isRequireName": "是",  *
	//              "explain": "实话实说",  *
	//              "maxSelect": 1,  *
	//              "minSelect": 1,  *
	//              "isMappingSelect": "0",  *
	//              "isMappingSelectName": "否",  *
	//              "style": null,  *
	//              "orderBy": 1, *
	//              "subItemDtoList": [
	//                  {
	//                      "id": 1, *
	//                      "optionExplain": "A.王曰强", *
	//                      "orderBy": 1, *
	//                      "fraction": null, *
	//                      "min": null, *
	//                      "minExplain": null, *
	//                      "max": null, *
	//                      "maxExplain": null, *
	//                      "isBlank": null, *
	//                      "isRequire": null, *
	//                      "mappingDetailId": null, *
	//                      "checkedFlag": false,  //如果是单选 多选 下拉框 量表时 选中的数据此变量置为true
	//                      "answerBlank": null  //如果是单选 多选 下拉框 量表时 选中的数据需要填空时候赋值给此变量
	//                  },
	//                  {
	//                      "id": 2,
	//                      "optionExplain": "B.孙乐乐",
	//                      "orderBy": 2,
	//                      "fraction": null,
	//                      "min": null,
	//                      "minExplain": null,
	//                      "max": null,
	//                      "maxExplain": null,
	//                      "isBlank": null,
	//                      "isRequire": null,
	//                      "mappingDetailId": null,
	//                      "checkedFlag": false,
	//                      "answerBlank": null
	//                  }
	//              ],
	//              "answerContent": null //如果是填空 简答 评分时 ，将填写的答案赋值给此变量
	//          }
	//      ]
 // } data
 */
export function submitQuestion(data,time,account,source, taskId, initiator) {
	return http({
		method: "post",
		contentType: "application/json;charset=UTF-8",
		type: 'stringfy',
		url: "/baseSurveyQuestion/submitQuestion.nd?isNoLogin=Y&beginDate=" + time + '&source=' + source + '&account=' + account + '&taskId=' + taskId + '&initiator=' + initiator,
		data: data
	});
}
